/* Body background with a sophisticated texture */
body {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8)),  no-repeat center center fixed;
    background-size: cover;
    font-family: 'Georgia', serif;
    color: #333;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    background-color:black;
}

/* Header (h2) styling */
h2 {
    text-align: center;
    font-size: 3em;
    margin: 60px 0;
    color: #333;
    font-weight: 900;
    letter-spacing: 2px;
    border-bottom: 3px solid #ddd;
    padding-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.85);
    display: inline-block;
    padding: 20px 30px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Flexbox container for images */
.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px;
    max-width: 1400px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

/* Image styling with soft border, hover effects, and subtle shadows */
.gallery-container img {
    margin: 15px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    width: 280px;
    height: 280px;
    object-fit: cover;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
    filter: brightness(100%) contrast(110%);
}

/* Hover effect to slightly enlarge images and enhance shadows */
.gallery-container img:hover {
    transform: scale(1.1);
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.15);
    filter: brightness(105%) saturate(125%);
}

/* Media query for smaller devices to ensure responsiveness */
@media (max-width: 992px) {
    h2 {
        font-size: 2.5em;
    }

    .gallery-container {
        flex-direction: column;
        align-items: center;
    }

    .gallery-container img {
        width: 80%;
        height: auto;
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 2em;
    }

    .gallery-container img {
        width: 90%;
    }
}
