/* Companylicenses.css */

.companylicenses-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .companylicenses-container h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .companylicenses-container p {
    font-size: 16px;
    margin: 10px 0;
    line-height: 1.6;
    color: #555;
  }
  
  .companylicenses-container p strong {
    color: #000;
  }
  
  .companylicenses-container p:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .companylicenses-container p:last-child {
    margin-bottom: 0;
  }
  