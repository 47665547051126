/* Agriculturalproducts.css */

.agriculturalproducts-container {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    max-width: 700px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .agriculturalproducts-container h2, .agriculturalproducts-container h3 {
    color: #2c3e50;
    margin-bottom: 15px;
    text-align: center;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #2c3e50;
    color: #fff;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  p {
    line-height: 1.6;
    font-size: 16px;
  }
  
  strong {
    color: #2c3e50;
  }
  