/* Business.css */

.business-container {
    background-color: #f9f9f9; /* Light gray background */
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Spacing inside the container */
    margin: 20px auto; /* Center the container with some margin */
    max-width: 600px; /* Set a max width */
    font-family: Arial, sans-serif; /* Font style */
    color: #333; /* Dark text color */
  }
  
  .business-container h2 {
    color: #2c3e50; /* Darker shade for the heading */
    font-size: 24px; /* Larger font size for the heading */
    margin-bottom: 15px; /* Space below the heading */
    text-align: center; /* Center the heading */
  }
  
  .business-container p {
    line-height: 1.6; /* Space between lines */
    font-size: 16px; /* Size of the text */
  }
  
  .business-container strong {
    color: #2c3e50; /* Highlighted color for section titles */
    font-weight: bold; /* Bold text for emphasis */
  }
  
  .business-container p br {
    margin-bottom: 8px; /* Spacing between lines */
  }
  
  